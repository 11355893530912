<template>
  <app-page
    name="Подтверждение email"
    icon="icon-user-icon"
    html-class="email-verification"
  >
    <template v-if="!finished">
      <div class="email-verification__process">
        <p>Подтверждение email</p>
        <p>Выполняется подтверждение, подождите...</p>
      </div>
    </template>
    <success
      v-else
      title="Вы сделали это!"
      content="Ваш email подтвержден!"
      redirectTo="profile"
    />
  </app-page>
</template>

<script>
import axios from "axios";
import AppPage from "@/components/AppPage.vue";
import Success from "@/components/Success";

export default {
  components: { AppPage, Success },
  data() {
    return { finished: false };
  },
  async mounted() {
    try {
      const { data } = await axios.post("/api/verify-and-update-email", {
        token: this.$route.query.token,
      });
      this.$store.commit("setUser", data);
      this.finished = true;
    } catch (e) {
      this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
    }
  },
};
</script>

<style lang="scss">
.email-verification__process {
  margin-bottom: 20px;
  padding: 10px 0;
  text-align: center;

  p {
    line-height: 1.5;
  }
}
</style>
