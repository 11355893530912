<template>
  <div class="success__wrap">
    <h1>{{ title }}</h1>
    <svg
      class="success__image"
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54 19.2V48.7656C54 50.2821 53.3609 51.6476 52.3403 52.6037C51.4145 53.4704 50.1759 54 48.815 54H5.18381C3.82286 54 2.58428 53.4704 1.65967 52.6046C0.639066 51.6476 0 50.2821 0 48.7656V19.2L54 19.2Z"
        fill="#FEC970"
      />
      <path
        d="M53.9996 19.5001L33.2435 2.25444C29.6259 -0.751479 24.3737 -0.751479 20.7561 2.25444L0 19.5001H0.000412031L6.11784 24.5827L20.7561 36.7449C24.3741 39.7517 29.6263 39.7517 33.2431 36.7449L47.8826 24.5827L54 19.5001"
        fill="#FBA028"
      />
      <path
        d="M48 8.94183V24.6801L33.2781 36.7602C29.6409 39.7466 24.3591 39.7466 20.7207 36.7602L6 24.6801V8.94183C6 5.66056 8.69951 3.00001 12.0297 3.00001H41.9712C45.3013 3.00001 48 5.66056 48 8.94183Z"
        fill="#BFDADD"
      />
      <path
        d="M48 8.4527V20.8127L33.2781 33.1187C29.6409 36.1604 24.3591 36.1604 20.7207 33.1187L6 20.8127V8.4527C6 5.11019 8.69951 2.39999 12.0297 2.39999H41.9712C45.3013 2.39999 48 5.11019 48 8.4527Z"
        fill="#E4F5F7"
      />
      <path
        d="M39.3504 23.3191H15.2444C14.7905 23.3191 14.4226 22.9477 14.4226 22.4894C14.4226 22.0315 14.7905 21.6597 15.2444 21.6597H39.3504C39.8043 21.6597 40.1722 22.0315 40.1722 22.4894C40.1722 22.9477 39.8043 23.3191 39.3504 23.3191Z"
        fill="#4B5051"
      />
      <path
        d="M39.3504 28.138H15.2444C14.7905 28.138 14.4226 27.7666 14.4226 27.3083C14.4226 26.85 14.7905 26.4786 15.2444 26.4786H39.3504C39.8043 26.4786 40.1722 26.85 40.1722 27.3083C40.1722 27.7666 39.8043 28.138 39.3504 28.138Z"
        fill="#4B5051"
      />
      <path
        d="M39.3509 13.6821H15.2449C14.791 13.6821 14.4231 13.3107 14.4231 12.8524C14.4231 12.3942 14.791 12.0227 15.2449 12.0227H39.3509C39.8048 12.0227 40.1727 12.3942 40.1727 12.8524C40.1727 13.3107 39.8048 13.6821 39.3509 13.6821Z"
        fill="#4B5051"
      />
      <path
        d="M39.3504 18.5006H15.2444C14.7905 18.5006 14.4226 18.1292 14.4226 17.6709C14.4226 17.2126 14.7905 16.8412 15.2444 16.8412H39.3504C39.8043 16.8412 40.1722 17.2126 40.1722 17.6709C40.1722 18.1292 39.8043 18.5006 39.3504 18.5006Z"
        fill="#4B5051"
      />
      <path
        d="M52.8005 11.3998C52.8005 17.696 47.6965 22.8 41.4007 22.8C35.1045 22.8 30.0005 17.696 30.0005 11.3998C30.0005 5.10401 35.1045 0 41.4007 0C47.6965 0 52.8005 5.10401 52.8005 11.3998Z"
        fill="#26BF64"
      />
      <path
        d="M52.7998 11.3992C52.7998 17.3876 48.1821 22.3006 42.3 22.8C36.4166 22.3006 31.7998 17.3876 31.7998 11.3992C31.7998 5.412 36.4166 0.499034 42.3 0C48.1821 0.499034 52.7998 5.412 52.7998 11.3992Z"
        fill="#49D685"
      />
      <path
        d="M41.6168 18.6C41.375 18.6 41.1427 18.4935 40.9846 18.3055L36.1936 12.6083C35.9004 12.2591 35.9457 11.7388 36.295 11.446C36.6446 11.1528 37.1656 11.1984 37.4588 11.5472L41.3865 16.2179L45.8147 5.9001C45.9942 5.48143 46.4802 5.28731 46.8994 5.46703C47.3187 5.64635 47.5131 6.13123 47.3331 6.55031L42.3762 18.0999C42.2634 18.3635 42.0216 18.5494 41.7378 18.5909C41.6975 18.5971 41.6571 18.6 41.6168 18.6Z"
        fill="white"
      />
      <path
        d="M48.9639 50.4C48.7755 50.4 48.5859 50.3375 48.4297 50.2092L35.1011 39.2586C34.7465 38.9671 34.6986 38.447 34.9936 38.097C35.2891 37.7471 35.8162 37.6994 36.1708 37.991L49.4989 48.9411C49.8539 49.2327 49.9023 49.7524 49.6068 50.1027C49.4414 50.2989 49.2035 50.4 48.9639 50.4Z"
        fill="#FBA028"
      />
      <path
        d="M18.8803 39.3513L7.9535 48.6929C6.44834 49.9801 7.12132 52.4402 9.07208 52.7797C12.21 53.3256 15.8767 53.938 16.8626 54H5.23C3.85662 54 2.6071 53.4704 1.67433 52.6046C0.64471 51.6477 0 50.2822 0 48.7657V19.2H3.94807V39.3538C3.98589 43.4278 8.75741 45.6141 11.8862 43.008L17.8395 38.0462C18.1999 37.747 18.739 37.8016 19.0304 38.1743C19.3118 38.5334 19.2303 39.0597 18.8803 39.3513Z"
        fill="#FBA028"
      />
    </svg>
    <slot>
      <p>{{ content }}</p>
    </slot>
    <button
      class="btn btn-primary success__btn"
      @click="$router.push(`${redirectTo}`)"
    >
      Cупер, спасибо!
    </button>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: () => "Мы сделали это!" },
    redirectTo: { type: String, default: () => "" },
    content: String,
  },
};
</script>

<style lang="scss">
.success {
  &__wrap {
    text-align: center;
  }

  &__image {
    margin: 15px auto;
    max-width: 362px;
  }

  &__btn {
    margin: 15px auto;
  }
}
</style>
